import {FC, ReactNode} from 'react';
import {useTranslation} from 'react-i18next';
import Icon, {IconProps} from '../shared/Icon';
import Card from '../shared/Card';
import {Box} from '../shared/Box';
import {Col, Row} from '../layout/Grid';
import Divider from '../shared/Divider';
import Price from '../shared/Price';
import Text from '../shared/Text';
import './InsuranceCard.scss';

interface InsuranceCardProps {
  icon: IconProps['component'];
  title: string;
  subTitle: string;
  selectInput?: ReactNode;
  price?: number;
  onSelectionChange?: () => void;
  children?: ReactNode;
  info?: ReactNode;
  dark?: boolean;
  priceText?: string;
  subPriceText?: string;
}

const InsuranceCard: FC<InsuranceCardProps> = ({
  icon,
  title,
  subTitle,
  selectInput,
  price,
  onSelectionChange,
  children,
  info,
  dark,
  priceText,
  subPriceText,
}) => {
  const {t} = useTranslation();

  return (
    <Card className={`insurance-card${dark ? ' dark' : ''}`}>
      <Box flex={1}>
        <Row align={'top'} className={'click-area'} onClick={() => onSelectionChange?.()}>
          <Col flex={'auto'} className={'icon-wrapper'}>
            <Icon component={icon} size={74}/>
            <div className={'select-wrapper'}>
              {selectInput}
            </div>
          </Col>
        </Row>
        <Text header={4}>{title}</Text>
        <Text size={'extra-small'} color={'turquoise'} strong opacity={0.6}>
          {subTitle}
        </Text>
        <br/>
        <br/>
        {children}
      </Box>
      {price !== undefined && (
        <Box flex={'0 0 50px'}>
          <Divider primary/>
          <Row align={'bottom'}>
            <Col flex={'auto'}>
              <Text opacity={0.4} size={'small'}>{priceText ?? t('insurance.monthlyRate')}</Text>
              {subPriceText && (
                <div>
                  <Text opacity={0.4} size={'extra-small'}>{subPriceText}</Text>
                </div>
              )}
              <br/>
              <Text opacity={0.4} size={'small'}>CHF </Text>
              <Text strong color={'gray-blue'}><Price value={price}/></Text>
            </Col>
            <Col>
              {info}
            </Col>
          </Row>
        </Box>
      )}
    </Card>
  );
};

export default InsuranceCard;
