import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {Spin} from 'antd';
import {observer} from 'mobx-react-lite';
import {useStore} from '../../hooks/useStore';
import {TravelInsurancePaths} from '../paths';
import RestoredSessionModal from '../../components/RestoredSessionModal';
import TravelInsurance from './initial-step/TravelInsurance';
import TravelInsuranceProductSelection from './product/TravelInsuranceProductSelection';
import TravelInsurancePersonalData from './person/TravelInsurancePersonalData';
import TravelInsuranceFamily from './person/TravelInsuranceFamily';
import TravelInsuranceOverview from './overview/TravelInsuranceOverview';
import TravelInsuranceSuccessSubmission from './success/TravelInsuranceSuccessSubmission';
import TravelInsurancePaymentFailed from './success/TravelInsurancePaymentFailed';
import NotFound from '../not-found/NotFound';

const TravelInsuranceRoutes = () => {
  const store = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const [isInitialLoad, setIsInitialLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showRestoredSessionModal, setShowRestoredSessionModal] = useState(false);

  useEffect(() => {
    const isStoreLoading = store.cityStore.isLoading
      || store.franchiseGroupStore.isLoading
      || store.baseInsuranceRateStore.isLoading
      || store.addonInsuranceRateStore.isLoading
      || store.premiumVersionStore.isLoading
      || store.calculatorStore.isLoading
      || store.franchiseChangeStore.isLoading
      || store.travelInsuranceTypeStore.isLoading
      || store.termOfInsuranceStore.isLoading
      || store.travelInsuranceRateStore.isLoading
      || store.travelInsuranceStore.isLoading;

    if (!isStoreLoading) {
      if (isInitialLoad) {
        store.travelInsuranceRateStore.models.forEach((m) => {
          m.setTravelInsuranceType(store.travelInsuranceTypeStore.getByUrn(m.travelInsuranceTypeUrn));
        });
        store.travelInsuranceRateStore.models.forEach((m) => {
          m.setTermOfInsurance(store.termOfInsuranceStore.getByUrn(m.termOfInsuranceUrn));
        });
      } else if (location.pathname.startsWith('/travel-insurance/finish/success')
        || location.pathname.startsWith('/travel-insurance/finish/payment-failed')) {
        setIsLoading(false);
      } else if (store.travelInsuranceStore.loadFromSession()) {
        if (!store.travelInsuranceStore.travelInsuranceDuration
            || !store.travelInsuranceStore.type || !store.travelInsuranceStore.travelInsuranceStartDate) {
          store.travelInsuranceStore.reset();
          navigate(TravelInsurancePaths.index());
          setIsLoading(false);
          return;
        }
        if (!store.travelInsuranceStore.travelInsuranceRate) {
          navigate(TravelInsurancePaths.productSelection());
          setIsLoading(false);
          return;
        }
        navigate(TravelInsurancePaths.person());
        setIsLoading(false);
      } else {
        store.travelInsuranceStore.reset();
        navigate(TravelInsurancePaths.index());
        setIsLoading(false);
      }
      setIsInitialLoad(false);
    }
  }, [
    store.cityStore.isLoading,
    store.franchiseGroupStore.isLoading,
    store.baseInsuranceRateStore.isLoading,
    store.addonInsuranceRateStore.isLoading,
    store.premiumVersionStore.isLoading,
    store.franchiseChangeStore.isLoading,
    store.travelInsuranceTypeStore.isLoading,
    store.termOfInsuranceStore.isLoading,
    store.travelInsuranceRateStore.isLoading,
    store.travelInsuranceStore.isLoading,
    isInitialLoad,
  ]);

  useEffect(() => {
    setIsInitialLoad(true);
  }, []);

  if (isLoading) {
    return <Spin style={{marginTop: 30, marginBottom: 30}}/>;
  }

  return (
    <>
      <Routes>
        <Route index element={<TravelInsurance/>}/>
        <Route path={'product'} element={<TravelInsuranceProductSelection/>}/>
        <Route path={'person'} element={<TravelInsurancePersonalData/>}/>
        <Route path={'person/family'} element={<TravelInsuranceFamily/>}/>
        <Route path={'overview'} element={<TravelInsuranceOverview/>}/>
        <Route path={'finish/success/:policyNr'} element={<TravelInsuranceSuccessSubmission/>}/>
        <Route path={'finish/payment-failed'} element={<TravelInsurancePaymentFailed/>}/>
        <Route path={'*'} element={<NotFound/>}/>
      </Routes>
      <RestoredSessionModal showModal={showRestoredSessionModal} setShowModal={setShowRestoredSessionModal}/>
    </>
  );
};

export default observer(TravelInsuranceRoutes);
